import Header from "@/components/global/header"

import axios from 'axios'
import jsonCities from '@/assets/colombia-cities-dep.json';

import L from 'leaflet';
import 'leaflet.markercluster'
import Vue2LeafletMarkercluster from 'vue2-leaflet-markercluster'
import { LMap, LTileLayer, LMarker ,LPopup, LIcon } from 'vue2-leaflet';

export default {
    name: 'correspondent-search',
    components:{
        'header-section': Header,
        LMap,
        LTileLayer,
        LMarker,
        'l-marker-cluster': Vue2LeafletMarkercluster,
        LPopup,
        LIcon
    },
    props:{
        autocomplete_department_label: String,
        autocomplete_city_label: String,
        autocomplete_error: String,
        button_search: String,
        title: String,
        subtitle: String,
        map_title: String
    },

    data: () => ({
        colombiaCities: jsonCities.data,
        selectedDepartment:null,
        selectedCity: null,
        filteredCities: [],
        correspondentArray: null,
        filteredCoordinates: null,
        progressBar: false,

        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        center: [ 4.762572524280281, -73.54222088907227 ],
        zoom: 6,
        minZoom: 6,
        mapOptions:{
            fadeAnimation:false,
        },
        clusterOptions:{
            maxClusterRadius: 160,
            spiderfyOnMaxZoom: false,
            disableClusteringAtZoom: 17,
            showCoverageOnHover: false,
            chunkedLoading: true,
            singleMarkerMode: true,
        }
    }),

    created(){

    },

    mounted(){
        this.$el.addEventListener("submit", (evt)=>{
            evt.preventDefault()
            this.onSubmit()
        })
    },

    methods:{

        onSubmit(){
            this.getData()
            this.progressBar = true
        },
        
        departmentChange(value){
            if(value){
                this.filterCities();
            }else{
                this.selectedCity = null;
                this.filteredCities = [];
            }
        },

        filterCities() {
            if (this.selectedDepartment) {
                this.filteredCities = this.colombiaCities
                .filter(loc => loc.department === this.selectedDepartment)
                .map(loc => loc.city)
            }
        },

        getItemText(item) {
            return `${item.department}`;
        },

        getData(){
            axios.get(`${process.env.VUE_APP_API_ENDPOINT}/api/correspondent-places?filters[department][$containsi]=${this.selectedDepartment}&filters[city][$containsi]=${this.selectedCity}`)
            .then((response) => {
                this.correspondentArray = JSON.parse(JSON.stringify(response.data.data))
                .filter(item => item.attributes.latitude != null && item.attributes.longitude != null);

                this.adjustMapView();
            })
            .catch(function (error){
                console.log("error",error)
            })
        },

        adjustMapView() {
            this.filteredCoordinates = this.correspondentArray.map((loc) => [
                loc.attributes.latitude,
                loc.attributes.longitude,
            ]);

            if (this.filteredCoordinates && this.filteredCoordinates.length > 0) {
                if (this.selectedDepartment === 'BOGOTA D.C.') {
                    const cleanBounds = this.filteredCoordinates.filter(([latitude, longitude]) => {
                        return latitude < 4.64 && latitude > 4.63 && longitude < -74 && longitude > -74.12;
                    });
                    const bounds = L.latLngBounds(cleanBounds);
                    this.$refs.map.mapObject.fitBounds(bounds, {
                        padding: [100, 100],
                    });
                } else {
                    const bounds = L.latLngBounds(this.filteredCoordinates);
                    this.$refs.map.mapObject.fitBounds(bounds, {
                        padding: [50, 50],
                    });

                }
            }
        },

        centerUpdated(center){
            this.center = center;
            setTimeout(() => {
                this.progressBar = false
            }, 600);  
        },
        scrollWindow(top){
            window.scrollTo({
                top: top,
                left: 0,
                behavior: "smooth",
              });
        },
    },

    computed: {

    },

    watch: {

    },
}