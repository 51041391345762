import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

import Home from '../views/Home'
import About from '../views/About'
import Feature from '../views/Feature'
import DownloadApp from '../views/DownloadApp'
import Privacy from '../views/Privacy'
import Snacks from '../views/Snacks'
import SnacksHome from '../views/SnacksHome'
import VtuAccount from '../views/VtuAccount'
import VtuCredit from '../views/VtuCredit'
import VtuCreditCard from '../views/VtuCreditCard.vue'
import SecurityTips from '../views/SecurityTips'
import Correspondent from '../views/Correspondent'
import NotFound from '../views/NotFound'

import when from 'when'
import axios from 'axios'
import slugify from '../js/utils/slugify'

const TRANSITION_DELAY_IN = 0.5
const TRANSITION_DELAY_OUT = 0.25

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'Lulo bank - Descarga y prueba la app',
      description: 'El banco que te devuelve plata y tiempo.'
    }
  },
  {
    path: '/descarga-lulo-bank',
    name: 'download-app',
    component: DownloadApp,
    meta: {
      title: 'Lulo bank - Descarga y prueba la app',
      description: 'Una cuenta de ahorros con $0 costos que te devuelve plata por ahorrar. Una tarjeta débito que te da cashback por tus compras. Un crédito que recibes en minutos.'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: {
      title: 'Lulo bank - Nosotros',
      description: 'El banco que te devuelve plata y tiempo. Pruébanos.'
    }
  },
  {
    path: '/features/:id',
    name: 'featuress',
    component: Feature,
    meta: {
      title: 'Lulo bank - ¿Qué puedes hacer en Lulo bank?',
      description: 'Prueba la Lulo cuenta con $0 costos que te devuelve plata por ahorrar. Una tarjeta débito que te da cashback por tus compras. Un Lulo crédito que se te aprueba en minutos.'
    }
  },
  {
    path: '/legals/:id',
    name: 'legalss',
    component: Privacy,
    meta: {
      title: 'Lulo bank - Legales',
      description: 'Conoce la información sobre nuestros productos y servicios, protección de datos y asuntos corporativos.'
    }
  },
  {
    path: '/snacks',
    name: 'snacks-home',
    component: SnacksHome,
    meta: {
      title: 'Lulo bank - Snacks',
      description: 'Tips para que tu plata crezca, salgas de deudas y mejores tu perfil crediticio.'
    }
  },
  {
    path: '/snacks/:id',
    name: 'snackss',
    component: Snacks
  },
  {
    path: '/simulador-ahorros',
    name: 'vtu-account',
    component: VtuAccount,
    meta: {
      title: 'Lulo bank - Simulador de ahorros',
      description: 'Calcula tus ahorros. Proyecta el total recibido y pagado durante un año.'
    }
  },
  {
    path: '/simulador-credito',
    name: 'vtu-credit',
    component: VtuCredit,
    meta: {
      title: 'Lulo bank - Simulador de credito',
      description: 'Proyecta lo que vas a pagar al final del plazo de tu crédito, el detalle de lo que abonas a capital, intereses y seguros.'
    }
  },
  {
    path: '/simulador-tarjeta-credito',
    name: 'vtu-credit-card',
    component: VtuCreditCard,
    meta: {
      title: 'Lulo bank - Simulador de tarjeta credito',
      description: 'Proyecta lo que vas a pagar al final del plazo de tu tarjeta de crédito, el detalle de lo que abonas a capital, intereses y seguros.'
    }
  },
  {
    path: '/tips-de-seguridad',
    name: 'security-tips',
    component: SecurityTips,
    meta: {
      title: 'Lulo bank - Tips de seguridad',
      description: 'Conoce nuestros canales oficiales y tips de seguridad para que tú y tu plata siempre estén protegidos.'
    }
  },
  {
    path: '/corresponsales',
    name: 'correspondent',
    component: Correspondent,
    meta: {
      title: 'Lulo bank - Corresponsales',
      description: 'Conoce nuestros corresponsales'
    }
  },
  {
    path: '/404',
    alias: '*',
    name: 'not-found',
    component: NotFound
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})
router.firstLoading = true
router.loadingIterations = 0


router.getInitialData = async ()=>{
  let defer = when.defer()

  // get token
  // const tokendata = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/auth/local`, {
  //   identifier: process.env.VUE_APP_API_IDENTIFIER,
  //   password: process.env.VUE_APP_API_PASSWORD,
  // })
  // router.TOKEN = tokendata.data.jwt
  // router.HEADERS = {
  //   headers: {
  //     Authorization: `Bearer ${router.TOKEN}`
  //   },
  // }
  router.HEADERS = {}

  // comon data 
  const header = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/api/header?populate=deep`, router.HEADERS)
  const footer = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/api/footer?populate=deep`, router.HEADERS)
  const wording = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/api/wording?populate=deep`, router.HEADERS)
  const alerts = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/api/alerts?populate=deep`, router.HEADERS)

  router.app.$store.state.header = header.data
  router.app.$store.state.footer = footer.data
  router.app.$store.state.wording = wording.data
  router.app.$store.state.alerts = alerts.data

  defer.resolve()
  return defer.promise
}

router.loadDataForPage = async (slug)=>{
  let defer = when.defer()
  const data = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/api/${slug}?populate=deep`, router.HEADERS)
  defer.resolve(data.data)
  return defer.promise
}

router.refineData = (data, pageName, to, next)=>{
  let pageData = null
  let routerSlug = null
  let slug = to.params.id
  if (slug !== undefined) {
    for (let i = 0; i < data.data.length; i++) {
      if (data.data[i].attributes.slug !== null && data.data[i].attributes.slug == slug){
        pageData = data.data[i]
        routerSlug = slugify(data.data[i].attributes.slug)
      } else if (data.data[i].attributes.title && slugify(data.data[i].attributes.title) == slug) {
        pageData = data.data[i]
        routerSlug = slugify(data.data[i].attributes.title)
      }
    }
    if (pageData == null) { 
      router.push('/404')
    } else {
      if (router.app.$store.state.page[pageName] == undefined) {
        router.app.$store.state.page[pageName] = {}
      }
      router.app.$store.state.page[pageName][routerSlug] = pageData
      next()
    }
  } else {
    router.app.$store.state.page[pageName] = data
    next()
  }
}

router.beforeEach((to, from, next) => {

  document.title = to.meta.title || '';
  
  let metaDescription = document.createElement('meta');
  metaDescription.name = 'description';
  metaDescription.content = to.meta.description;
  let metaDescriptionTag = document.querySelector('meta[name="description"]')
    if(metaDescription.content && !metaDescriptionTag){
      document.getElementsByTagName('head')[0].appendChild(metaDescription);
    }else{
      document.querySelector('meta[name="description"]').setAttribute('content',to.meta.description || '')
    }
  //document.querySelector('meta[name="description"]').setAttribute('content',to.meta.description || '')

  if (router.firstLoading) {
    window.scrollTo(0, 0)
    router
      .getInitialData()
      .then(()=>{
        let pageName = to.matched[0].name
        router.loadDataForPage(pageName).then((data)=>{
          router.refineData(data, pageName, to, next)
        })
      })
  } else {
    router.app.$children[0].$refs.transition.show()
    setTimeout(() => {
      window.scrollTo(0, 0)
      let pageName = to.matched[0].name
      router.loadDataForPage(pageName).then((data)=>{
        router.refineData(data, pageName, to, next)
        router.app.$nextTick(()=>{
          for (let i = 0; i < to.matched.length; i++) {
            const element = to.matched[i].instances.default;
            if (element.loadData) {
              element.loadData().then(()=>{
                setTimeout(() => {
                  router.app.$children[0].$refs.transition.hide()
                }, (TRANSITION_DELAY_OUT * 1000));
              })
            } else {
              setTimeout(() => {
                router.app.$children[0].$refs.transition.hide()
              }, (TRANSITION_DELAY_OUT * 1000));
            }
          }
        })
      })
    }, (TRANSITION_DELAY_IN * 1000));
  }
  router.firstLoading = false
  router.loadingIterations++
})

export default router
